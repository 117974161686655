$light-color-gradient: #fcfcfc;
$dark-color-gradient: rgba(4, 114, 219, 0);
$background-inside: #0472db;

.loader {
  display: inline-block;
  top: 1px;
  font-size: 10px;
  margin: 0 1rem;
  text-indent: -9999em;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: $light-color-gradient;
  background: -moz-linear-gradient(left, $light-color-gradient 10%, $dark-color-gradient 42%);
  background: -webkit-linear-gradient(left, $light-color-gradient 10%, $dark-color-gradient 42%);
  background: -o-linear-gradient(left, $light-color-gradient 10%, $dark-color-gradient 42%);
  background: -ms-linear-gradient(left, $light-color-gradient 10%, $dark-color-gradient 42%);
  background: linear-gradient(to right, $light-color-gradient 10%, $dark-color-gradient 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader:before {
  width: 50%;
  height: 50%;
  background: $light-color-gradient;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader:after {
  background: $background-inside;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
