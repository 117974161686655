.title {
    font-size: 3.6rem;
    font-weight: 600;
}

.subTitle{
    font-size: 2.1rem;
}

.link{
    font-size: 1.6rem;
}