.alert {
  font-size: 1.4rem;
  padding: 1.6rem 1.5rem;
  border: 0;
  margin-bottom: 2rem;
  background-color: #f5e4e4;
  color: #b84b4b;
}

.alertIcon {
  position: relative;
  top: -4px;
  display: inline-block;
  font-family: "Sans serif";
  font-weight: 900;
  margin-right: 5px;
}

.alertIcon::before {
  content: "!";
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  border-radius: 50%;
  background-color: #b84b4b;
  color: #f4cbca;
  width: 14px;
  height: 14px;
  font-size: 12px;
  line-height: 14px;
}
