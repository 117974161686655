.wrapper {
    background-color: #fff;
    background-image: none !important;
    background-image: none;
    padding: 0 !important;
    border: 1px solid #a9a9a9;
    border-radius: 3px;
    > div:first-child {
        background-color: #f5f5f5;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border: none;
        border-bottom: 1px solid #ddd !important ;
        > span {
            background-color: #fff !important;

            > button {
                min-width: 3.5rem;
                min-height: 3rem;
                padding: 0.5rem 1rem;
                border-radius: 3px;
                border: 1px solid #ccc;
                margin-right: -1px;
            }

            > button:hover,
            > span:hover {
                background-color: #f5f5f5;
                color: unset !important;
            }

            > span {
                border: 1px solid #ccc;
                min-height: 3rem;

                ::before {
                    line-height: 2.6rem;
                }
            }
        }
    }

    > div:nth-child(2) { 
        overflow: unset;
    }
}

.wrapper[class*='is-invalid']:focus-within{
    box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
}

.inputArea {
    resize: vertical;
    overflow: auto;
    border-radius: 3px;
    min-height: 20rem;
    border: none !important;
    *::before {
        font-style: normal !important;
        font-size: 1.4rem;
    }
}
