@use 'styles/variables/colors';
@use '~styles/utils/mixins';

.formGroup {
    position: relative;
    margin-bottom: 1.5rem !important;
}

.label {
    font-weight: 700 !important;
    text-transform: capitalize !important;
}

.formInputFieldLabel {
    justify-content: flex-start !important;
    @include mixins.respond-to('md') {
        justify-content: flex-end !important;
    }
}

.labelText {
    margin: 0;
    padding: 0;
}

.labelText::first-letter {
    text-transform: uppercase !important;
}
.input {
    // width: 100%;
    input:not([type='checkbox']):not([type='radio']),
    select,
    textarea {
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857;
        color: #868686;
        background-color: #fff;
        background-image: none !important;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    // ! Note: The checkbox styles could be merged into Zoran's new  component once this branch has been merged
    input:not([type='checkbox']):not([type='radio']):focus,
    input:not([type='checkbox']):not([type='radio']):active {
        color: #212529;
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    }

    select {
        background-color: colors.$gray-wildsand !important;
    }

    textarea {
        min-height: 8rem !important;
    }
}
