@mixin font-face(
  $font-family,
  $file-path,
  $weight: normal,
  $style: normal,
  $asset-pipeline: false
) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-size: $aaa;

    @if $asset-pipeline == true {
      src: font-url("#{$file-path}.eot");
      src: font-url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
      font-url("#{$file-path}.woff") format("woff"),
      font-url("#{$file-path}.ttf") format("truetype"),
      font-url("#{$file-path}.svg##{$font-family}") format("svg");
    } @else {
      src: url("#{$file-path}.eot");
      src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
      url("#{$file-path}.woff") format("woff"),
      url("#{$file-path}.ttf") format("truetype"),
      url("#{$file-path}.svg##{$font-family}") format("svg");
    }
  }
}

@mixin column($count) {
  -webkit-column-gap: $grid-gutter-width;
  -moz-column-gap: $grid-gutter-width;
  column-gap: $grid-gutter-width;
  padding: 0 ($grid-gutter-width / 2) 0 ($grid-gutter-width / 2);
  -webkit-columns: auto #{$count};
  -moz-columns: auto #{$count};
  columns: auto #{$count};
}

$breakpoints: (
        "xs": (
                min-width: 480px,
        ),
        "sm": (
                min-width: 576px,
        ),
        "md": (
                min-width: 768px,
        ),
        "lg": (
                min-width: 992px,
        ),
        "xl": (
                min-width: 1200px,
        ),
        "xxl": (
                min-width: 1400px,
        ),
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
