@use "styles/variables/colors";

.authOptionsWrapper {
  margin-left: auto;
  margin-right: -15px;
  flex-direction: row !important;

  a,
  a:link,
  a:hover,
  a:visited,
  a:active {
    padding: 2.5rem 1.5rem !important;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: colors.$white !important;
    text-decoration: none;
    font-weight: 300;
  }
}

.createQuestBtn {
  display: flex;
  align-items: center;

  >button {
    font-size: 1.6rem;
    padding: 0.6rem 4rem !important;
    background: colors.$blue-science !important;
    border-radius: 5px;
    margin: auto 0;
    font-weight: 500;
    margin-right: 10px;
  }
}

.dropdown {
  > a[aria-expanded="true"] {
    background-color: #002547;
  }

  > div {
    background-color: colors.$white;
    margin: 0 !important;
    border: 0 !important;
    border-radius: 0;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    position: absolute !important;

    a,
    a:link,
    a:hover {
      font-size: 1.4rem;
      padding: 0 2rem !important;
      color: #262626 !important;
    }

    a:hover,
    a:active,
    a:visited {
      background-color: colors.$gray-wildsand;
      color: #262626 !important;
    }
  }
}
