//
// Global css overrides for container
// --------------------------------------------------


// Container widths
$sm-width: 750px;
$md-width: 970px;
$lg-width: 1170px;

.container {
  @media (min-width: $screen-sm-min) {
    width: $sm-width;
  }
  @media (min-width: $screen-md-min) {
    width: $md-width;
  }
  @media (min-width: $screen-lg-min) {
    width: $lg-width;
  }
}