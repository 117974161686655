html {
  min-height: 100%;
  font-size: 10px;
}

body {
  font-family: 'Titillium Web', sans-serif;
  min-height: 100vh;
  padding-bottom: 40px;
  background: #fff;
  font-size: 16px;
  line-height: 1.43em;
  position: relative;
}

b,
strong {
  font-weight: 800;
}

// These classes should be places elsewhere probably
// will let them stay here for now
a {
  color: $blue-science;

  &:hover {
    text-decoration: underline;
    color: $blue-science;
  }

  &:focus,
  &:visited {
    text-decoration: none;
    color: $blue-science;
  }
}

h1,
h2,
h3 {
  margin-bottom: 20px;
  font-weight: 600;

  &.thin {
    font-weight: normal;
  }
}

h2 {
  font-size: 24px;

  &.border {
    position: relative;
    padding: 0 0 25px;

    &:before {
      @extend %pseudo;
      width: 62px;
      height: 3px;
      background: $blue-science;
      bottom: 0;
      left: 0;
    }
  }
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

.page-wrapper {
  position: relative;
  padding-bottom: 300px; /* footer height */
}

.bg-white {
  background: #fff;
}

.bg-shadow {
  position: relative;
  background: #fff image-url("./img/bg-shadow.png") repeat-x top center;
}

.icon-tooltip {
  width: 17px;
  height: 17px;
  background: image-url("./img/icon-tooltip.png") no-repeat center;
  display: inline-block;
  margin: 0 5px;
  position: relative;
  top: 3px;

  &-man,
  &-woman {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    top: 0;
    margin: 0 5px;
  }

  &-man {
    background: $blue-science image-url("./img/icon-man.png") no-repeat center;
  }

  &-woman {
    background: $blue-science image-url("./img/icon-woman.png") no-repeat center;
  }
}

.lead {
  margin-bottom: $line-height-computed;
  font-size: floor(($font-size-base * 1.15));
  font-weight: 300;
  line-height: $line-height-base;

  @media (min-width: $screen-sm-min) {
    font-size: ($font-size-base * 1.5);
  }
}

.tooltip > .tooltip-inner {
  background-color: #fff !important;
  color: #000;
  border: 1px solid darkgrey;
  box-shadow: 0px 2px 7px darkgrey;
  padding: 2px;
  padding: 4px 8px;
  font-size: 1.2rem;
}

.tooltip > .tooltip-arrow::before {
    border-top-color: #fff !important;
}

.btn-outline-primary:hover {
  border-color: #0472db;
  color: #0472db;
  background-color: #f3f3f3;
}

#tooltipInfo{
  opacity: 1;
}

.tooltip.show{
  opacity: 1 !important;
}

.form-control {
  color: #868686 !important;
  padding: 0 0.5rem !important;
  border-radius: 3px !important;
  background-image: none !important;
}
.form-control::placeholder {
  padding: 0 0.5rem;
  font-size: 1.4rem;
}

