.textWrapper {
    padding: 3.5rem 0 !important;
    font-size: 1.4rem;

    h3 {
        font-size: 2.2rem;
    }

    p {
        line-height: 1.4em;
    }

    a,
    a:hover,
    a:active,
    a:link,
    a:visited {
        text-decoration: underline;
        color: #fff;
    }
}
.iconTooltip {
    width: 17px;
    height: 17px;
    background: url(./icon-tooltip.png) no-repeat center;
    display: inline-block;
    margin: 0 5px;
    top: 4px;
    position: relative;
}