.wrapper {
    .h1 {
        font-size: 3.6rem;
        font-weight: 600;
    }

    .p {
        font-size: 2.1rem;
    }

    .a {
        font-size: 1.6rem;
    }
}