@use '~styles/variables/colors';

.fieldError {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
    margin-left: 0.1rem;
}

.helpInfoText {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: #737373;
    font-size: 1.3rem;
}
