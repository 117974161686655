@use '~styles/utils/mixins.scss';

.modal-lg{
    width: auto;
    max-width: unset;
    margin: 10px;
    @include mixins.respond-to('md'){
        width: 800px;
        margin: 30px auto;
    }
    @include mixins.respond-to('lg'){
        width: 800px;
        width: 900px;
    }
}