@use '~styles/variables/colors';

.selectContainer {
    font-size: 1.5rem !important;

    input,
    div {
        font-size: 1.4rem !important;
    }

    a {
        text-decoration: none !important;
        font-size: 1.4rem !important;
    }

    input {
        color: colors.$gray  !important;
        height: 3.4rem !important;
        border-radius: 3px !important;
        background-image: none !important;
    }

    input::placeholder {
        padding: 0 0.5rem;
        font-size: 1.4rem;
    }

    a{
        display: flex;
        align-items: center;
    }

}

.selectContainerWithApiCall {
    a {
        text-decoration: underline !important;
    }

    [class~='rbt-token'] {
        display: inline;
        max-width: 20%;
        background-color: #5bc0de;
        border-radius: 3px;
        color: colors.$white;
        padding: 0.1rem 0.5rem;
        margin-right: 0.2rem;
        position: relative;
        top: 0.5rem;
    }

    [class~='rbt-token-label'] {
        font-size: 1.2rem !important;
        display: inline-block;
    }

    [class~='rbt-close-content'] {
        display: none;
    }

    [class~='rbt-close'] {
        display: inline-block;
        font-size: 0.7rem;
        padding: 0.5rem 0.5rem 0;
        position: relative;
        color: colors.$white;
        top: -1px;
        opacity: 1;
        filter: invert(100%);
        border: 0;
    }

    [class~='rbt-input-wrapper'] {
        >div {
            display: inline !important;
        }
    }

    [class~='rbt-input-hint'] {
        display: none;
    }
}