.container {
    position: fixed !important;
    width: 100vw;
    left: 0;
    bottom: 0;
    z-index: 2;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    transition: 0.5s !important;
    transition-property: bottom !important;
}

.contentWrapper{
    width: 100%;
    max-width: 1155px;
    position: relative;

    >p {
        margin: 0;
        padding: 10px 0;
    }
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem;
}
