@use "~styles/variables/colors";
@use "~styles/utils/mixins";

.example {
  background-color: colors.$blue;
  min-height: 71px;
  padding-top: 20px !important;
  margin-bottom: 2.5rem;
  @include mixins.respond-to('lg'){
    padding: 0 !important;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  margin-left: -7px;
  margin-bottom: 4px;
  text-decoration: none;
}

.logoWrapper:hover {
  text-decoration: none;
}

.title {
  display: inline-block;
  color: colors.$white;
  font-weight: 300;
  display: inline-block;
  vertical-align: middle;
  margin: 0 2.8rem 2px;
  line-height: 2rem;
}

.authOptionsWrapper {
  margin-left: auto;
  margin-right: -15px;
  flex-direction: row !important;

  a,
  a:link,
  a:hover,
  a:visited,
  a:active {
    padding: 2.5rem 1.5rem !important;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: colors.$white !important;
    text-decoration: none;
    font-weight: 300;
  }
}

.createQuestBtn {
  display: flex;
  align-items: center;

  button {
    font-size: 1.6rem;
    padding: 0.6rem 4rem !important;
    background: colors.$blue-science !important;
    border-radius: 5px;
    margin: auto 0;
    font-weight: 500;
    margin-right: 10px;
  }
}
