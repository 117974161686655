// Colors -> pallete is wtf but let it stay like this for now and see what we are going to need
$blue-malibu: #7dc0ff;
$blue: #003f7a;
$blue-science: #0472db;
$blue-endeavour: #0054a4;
$blue-viking: #67c6de;
$yellow-tanhide: #fbaf5d;
$red: #e44646;
$yellow-selection: #ffff99;
$lavender: #c967de;
$gray: #868686;
$gray-mercury: #e8e8e8;
$gray-wildsand: #f5f5f5;
$gray-concrete: #f3f3f3;
$gray-gallery: #ededed;
$gray-alto: #d8d8d8;
$gray-silverchalice: #a1a1a1;
$gray-silver: #cccccc;
$gray-mineshaft: #333333;
$gray-dusty: #999999;
$gray-alabaster: #f9f9f9;
$green: #91cf6c;
$green-salad: #5eaf2d;
$white: #ffffff;

//## Gray and brand colors for use across Bootstrap.
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 60%) !default; // original was #777 (46.7%)
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee