.customButton {
  padding: 6px 40px !important;
  font-size: 1.4rem !important;
  display: block !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
  transition: all 0.2s !important;
  border-width: 2px !important;
}

