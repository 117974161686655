@use '~styles/variables/colors';

.header {
    background: #0054a4 !important;
    color: #fff !important;
    padding: 2.2rem 2.5rem !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.headerReduced {
    position: fixed !important;
    width: 700px !important;
    height: 60px !important;
    left: 50%;
    transform: translate(-50%);
    bottom: 0 !important;
    z-index: 100 !important;
    overflow: hidden;
    >button{
        top: 8px;
    }
}

.modalTitle {
    font-size: 3.4rem !important;
    font-weight: 800 !important;
    margin: 0 !important;
    line-height: 1.4em !important;
}

.button,
.button:hover {
    position: absolute;
    top: 0px;
    right: 10px;
    background: colors.$blue-endeavour !important;
    border-color: colors.$blue-endeavour !important;

    > span {
        color: #b9b9b9;
        opacity: 1;
        text-shadow: none;
        font-size: 30px;
        line-height: 43px;
        font-weight: 500;
    }
}

.leftButton {
    padding: 0;
}

.leftButton,
.leftButton:hover {
    position: absolute;
    top: 0;
    right: 55px;
    background: colors.$blue-endeavour !important;
    border-color: colors.$blue-endeavour !important;

    > span {
        color: #b9b9b9;
        opacity: 1;
        text-shadow: none;
        font-size: 30px;
        line-height: 43px;
        font-weight: 500;
    }
}
