@use 'styles/variables/colors';

.footer {
    position: absolute;
    margin-top:auto;
    bottom: 0;
    width: 100%;
    background: #003f7a;
    color: #fff;
}

.navbar {
    margin: 0;
    background: colors.$blue-science;
    text-align: center;
    font-size: 1.6rem;
    border: 0;
    border-radius: 0;
    display: flex;
    justify-content: center;
}

.navitem {
    a,
    a:link,
    a:hover,
    a:active,
    a:visited {
        padding: 1.5rem;
        color: colors.$white;
        text-decoration: none;
        font-size: 0.85em;
        line-height: 2rem;
        font-weight: 300;
    }
}
