@use '~styles/variables/colors';

.button,
.button:hover {
    position: absolute;
    top: 0px;
    right: 10px;
    background: colors.$blue-endeavour !important;
    border-color: colors.$blue-endeavour !important;

    > span {
        color: #b9b9b9;
        opacity: 1;
        text-shadow: none;
        font-size: 43px;
        line-height: 43px;
        font-weight: 500;
    }
}

.leftButton,
.leftButton:hover {
    position: absolute;
    top: 5px;
    right: 55px;
    background: colors.$blue-endeavour !important;
    border-color: colors.$blue-endeavour !important;

    > span {
        color: #b9b9b9;
        opacity: 1;
        text-shadow: none;
        font-size: 30px;
        line-height: 43px;
        font-weight: 500;
    }
}

.modalDefault {
    > div {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}
